import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const PropertyManagers = () => {
    return (
        <Layout>
            <Seo
                title="Gestionnaires Immobiliers - BIKESAFE"
                description="L'espace, le rapport qualité-prix et la sécurité sont des points importants pour les gestionnaires immobiliers.  Bike Safe propose des systèmes de rangement pour vélos qui répondent aux besoins des gestionnaires immobiliers pour tous les types d'installation..."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Gestionnaires Immobiliers
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                            L'espace, le rapport qualité-prix et la sécurité sont des points importants pour les gestionnaires immobiliers.  Bike Safe propose des systèmes de rangement pour vélos qui répondent aux besoins des gestionnaires immobiliers pour tous les types d'installation, qu'ils s'agissent de supports ou d'abris vélos. Si vous souhaitez aménager un abri vélo, consacrer une pièce entière pour le stationnement des vélos ou juste un espace, nous avons la solution !
                        </p>
                        <p className="flow-text">
                            En élaborant un plan CAO de votre espace, nous pouvons vous aider à maximiser votre système de rangement pour vélos.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bristol-shelter.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">« Merci beaucoup pour le superbe travail effectué sur les supports vélos. Ils sont super et les habitants de la résidence les utilisent déjà ! »
                                <strong> John Hilton, SMBC Group (Gestionnaires immobiliers), Londres</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PropertyManagers;